@import "./../../styles/global";
@import './../../styles/variables';
@import './../../styles/functions';
@import "~bootstrap/scss/bootstrap";


.data__table {
  background-color: $white;
  border-radius: calcRem(12px);
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: calcRem(10px);

  .other{
    overflow-x: scroll;
  }

  table{
    color: $black;
    line-height: calcRem(35px);
    thead {
        font-family: $primaryFont;
    }
    tbody {
        font-family: $primaryFont;
    }

  }

  th {
    white-space: nowrap;
    padding: 3px 20px!important;
  }

  td {
    font-size: $fontSizeExtraSmall;
    padding: 3px 20px!important;
    white-space: nowrap;
  }

  &__actions {
    display: flex;
    flex-direction: row;
    gap: 13px;

    &__action {
        color: $primaryActionColor;
      &:hover {
        cursor: pointer;
      }
    }
  }

  &__top .row {
    font-family: $primaryFont;
    color: $primaryActionColor;
    display: flex;
    align-items: center;
    padding-top: calcRem(10px);
  }

}

.th--active {
  color: $black;
}

.table-icons {
  display: flex;
  justify-content: space-around;
}

.table {
  color: $black !important;
}

th {
  font-weight: $bold !important;
  color: $grey !important;
}

.overflow-ellipsis {
  text-overflow: ellipsis;
  width: 300px;
}

.table__footer {
    color: $primaryActionColor;
    font-family: $primaryFont;
    font-size: $fontSizeExtraSmall;
  &__left {
    @include media-breakpoint-only(xs) {
      font-size: calcRem(12px);
      div > .dropdown > div {
        margin-bottom: 0 !important;
      }
    }

    @include media-breakpoint-only(sm) {
      font-size: calcRem(12px);
      div > .dropdown > div {
        margin-bottom: 0 !important;
      }
    }
  }
  &__right {
    @include media-breakpoint-only(xs) {
      margin-top: calcRem(10px);
    }
    @include media-breakpoint-only(sm) {
      margin-top: calcRem(10px);
    }
  }
}

.page-item.page-link {
    color: $primaryActionColor !important;
}

.thead-dark {
  background-color: $grey !important;
}

.table > :not(caption) > * > * {
    background-color: $foregroundColor !important;
    color: $black;
    font-weight: $regular;
}

.table-striped>tbody>tr:nth-of-type(odd) > * {
    color: $black !important;
    border: none;
    background-color: $backgroundColor !important;
}

.table-dark{
    background-color: $foregroundColor !important;
    --bs-table-striped-bg: #ebebeb !important;
    border: none;
}

.table > :not(:first-child) {
    border-top: 0px !important;
}


.column-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.column-image{
  margin-left:5px;
}

.paging{
  &__color-black{
    color: $black;
  }
  &__no-data{
    display: flex;
    width:calcRem(130px);
    justify-content: center;
    align-self: center;
    background-color: $blue;
    color: $offwhite;
    border-radius: calcRem(6px);
  }
/*  &__border-radius{
    border-radius: calcRem(6px) !important;
    border: 1px solid $grey-border !important;
  }*/

  &__page-item{
display:flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  &__page-items {
    background-color: transparent !important;
    color: $grey !important;
    font-family: $primaryFont;
    font-size: $fontSizeExtraSmall;
    border-radius: calcRem(6px);
    border: 1px solid $grey-border;
    padding: calcRem(5px) calcRem(10px);
    margin: 0px 5px;
    @include media-breakpoint-down(md) {
      padding: calcRem(2px) calcRem(6px);
      margin: 0px 2px;
    }
    &.active {
      box-shadow: #00000029 1px 1px 3px;
      border-radius: calcRem(6px);
      background-color: $blue !important;
      color: $white !important;
    }
  }
  &__arrow{
    cursor: pointer;
  }
  &__arrow--left{
    transform: rotate(180deg);
  }

  &__li-item-active {
    color: $white !important;
  }
  &__footer{
    margin-right: calcRem(10px);
  }
  @include media-breakpoint-up(md) {
    margin: calcRem(20px);
  }
}
