@import "../styles/variables";
@import "../styles/functions";
@import "~bootstrap/scss/bootstrap";


.pill__form{

  background: $actionColor;
  padding: calcRem(5px);
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: calcRem(4px) calcRem(2px);
  cursor: pointer;
  border-radius: calcRem(16px);
  width: auto !important;

  &__usage{
    @extend .pill__form;
    background: $materialsRed;
  }
  &__functionality{
    @extend .pill__form;
    background: $materialsOrange;
  }
  &__material{
    @extend .pill__form;
    background: $materialsBlue;
  }
  &__component{
    @extend .pill__form;
    background: $materialsGreen;
  }
  &__generic{
    @extend .pill__form;
    background: $materialsGrey;
  }
}


.pill__form:hover {
  background: $successColor;
}

.img-delete {

  &:hover {
    cursor: pointer;
  }
  box-shadow: calcRem(1px) calcRem(2px) calcRem(2px) grey;
  width: calcRem(30px);
  height: calcRem(30px);
  background-color: $foregroundColor;
  border-radius: 50%;
  font-size: calcRem(30px);
  color: $primaryActionColor;
  position: relative;
  transform: translateX(130px) translateY(calcRem(40px));
  z-index: 2;
  svg {
    margin-top: calcRem(-20px);
  }
}

.img-preview {
  box-shadow: calcRem(1px) calcRem(2px) calcRem(2px) grey;
  width: calcRem(150px);
  height: calcRem(150px);
  object-fit: cover;
  margin: calcRem(20px) calcRem(20px) calcRem(5px) calcRem(0px);
  border: thin dotted #DEDEDE;
}

.modal {
  z-index: 1001 !important;
  overflow: hidden !important;
}

.modal-header{
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

.modal-body{
  padding-top: 0 !important;
  margin-top: 0 !important;
}


.modal-btn {
  z-index: 10001 !important;
}

.modal-backdrop {
  z-index: 10 !important;
}

.pac-container {
  z-index: 1001 !important;
  position: fixed;
  display: inline-block;
  float: left;
  overflow: scroll !important;
  height: calcRem(130px);
}

.form-label {
  font-size: calcRem($fontSizeSm);
  padding-left: calcRem(5px);
  margin-top: calcRem(5px);
}

.e-button {
  margin-bottom: calcRem(15px);
}


.file-pill{
  font-style: italic;
}

.wizard{
  &__header{
    padding: calcRem(20px);
  }
  &__body{
    background-color: $offwhite;
    font-size: calcRem($fontSizeMd);
    padding: calcRem(20px);
  }
  &__title{
    font-size: calcRem(35px);
    margin-bottom: calcRem(20px);
  }
  &__text-container {
    width: 60%;
  }
  &__button-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: calcRem(20px);
  }
  &__skip-prompt{
    color: $blue;
    text-decoration: underline;
    cursor: pointer;
    margin: calcRem(20px) 0 calcRem(20px) 0;
    :hover{
      filter: contrast(70%);
    }
  }
}

.modal-header{
  margin-bottom: 30px !important;
}

.dropdown-toggle{
  -webkit-box-shadow: 0 calcRem(2px) calcRem(5px) 0 rgba(0, 0, 0, 0.2)!important;
  -moz-box-shadow: 0 calcRem(2px) calcRem(5px) 0 rgba(0, 0, 0, 0.2)!important;
  box-shadow: 0 calcRem(2px) calcRem(5px) 0 rgba(0, 0, 0, 0.2)!important;
}

.category {
  &__container{
    padding: calcRem(5px) calcRem(20px) calcRem(20px) calcRem(20px);
    background-color: $white;
    margin-bottom: calcRem(20px);
    border-radius: calcRem(6px);
    p{
      font-size: $fontSizeMd;
    }
  }
  &__label{
    font-size: calcRem(20px);
    width: auto;
    font-weight: $bold;
    color: $black;
    margin-bottom: calcRem(20px);
    padding: 0 !important;
  }
  &__sub-label{
    font-size: calcRem($fontSizeMd);
    padding-left: calcRem(5px);
    margin-top: calcRem(20px);
    &-file-picker{
      margin-top: calcRem(0px);
    }
  }
  &__bg--grey{
    background-color: $offwhite;
    padding: calcRem(10px);
    margin-top: calcRem(20px);
  }
}

.map-selected-location{
  margin-top: calcRem(50px);
}
.form-renderer-button-container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media only screen and (max-width: 768px) {
    position: fixed;
    left: calcRem(5px);
    right: calcRem(5px);
    bottom: calcRem(2px);
  }
}
.offer-button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.offer-right-buttons {
  display: flex;
  gap: 10px;
}
.primary-image-container{
  padding: 0 !important;
  background-color: $offwhite;
  margin-bottom: calcRem(20px);
  border-radius: calcRem(6px);
  &__label{
    font-size: calcRem($fontSizeLg);
    width: auto;
    font-weight: $bold;
    color: $black;
    margin-bottom: calcRem(20px);
  }
}

.action-link {
  color: $blue;
  border: 0;
  background-color: transparent;
  padding-left:0;
  padding-right:0;
  font-size: calcRem(15px);
  text-decoration: underline;
  cursor: pointer;
  margin-left: calcRem(5px);
  &:hover {
    filter: brightness(85%);
  }
}

.estimation-label{
  font-size: calcRem(15px);
  color: $black;
  //margin-bottom: calcRem(10px);
  &-strong{
    font-weight: bold;
  }
}


.estimation-value{
  font-size: calcRem(15px);
  color: $grey;
  word-break: break-word;
  margin-bottom: calcRem(10px);
}

.estimation-container{
  background-color: #f5f5f5;
  padding: calcRem(15px) calcRem(20px) calcRem(20px) calcRem(20px);
  margin-bottom: calcRem(20px);
  border: 1px solid #8D8D8D59;
  border-radius: 8px;
}

.reEstimation-container{
  background-color: $abbCautionOrange;
  padding: calcRem(15px) calcRem(10px) calcRem(10px) calcRem(10px);
  margin-bottom: calcRem(20px);
  border-radius: 8px;
}

.checkbox .form-check-label {
  font-size: calcRem(15px);
}

.shipment {
  &__container {
    background-color: $white;
    margin-bottom: calcRem(20px);
    border-radius: calcRem(6px);
    div {
      margin-top: calcRem(5px);
    }
  }
}
