@import "../../../styles/functions";
@import "../../../styles/variables";
@import "~bootstrap/scss/bootstrap";


.striped-table {
  width: 100%;
  border-collapse: collapse;

  &__header{
    display:flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: calcRem(5px);
  }

  &__title{
    font-size: calcRem($fontSizeXLg);
    font-weight: bold;
  }

  &__info{
    position: relative;
    display: inline-block;
    z-index: 2;
  }

  &__container{
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    font-size: calcRem($fontSizeMd);
  }

  &__info .info-box{
    visibility: hidden;
    width: calcRem(300px);
    background-color: #f9f9f9;
    color: #000;
    text-align: center;
    border-radius: calcRem(6px);
    padding: calcRem(5px) 0;
    position: absolute;
    z-index: 1;
    bottom: 125%; /* Position above the element */
    left: 50%;
    margin-left: calcRem(-100px); /* Center the tooltip */
    opacity: 0;
    transition: opacity 0.3s;
  }

  &__info:hover .info-box {
    visibility: visible;
    opacity: 1
  }

  &__actions {
    display: flex;
    flex-direction: row;
    gap: calcRem(13px);

    &__action {
      color: $blue;
      font-size: calcRem($fontSizeMd);
      width: fit-content;
      cursor: pointer;
    }
  }

  &__links {
    margin-left: calcRem(8px);
    display: flex;
    align-items: center;
    color: $secondaryActionColor;
    cursor: pointer;
  }

  &__blue-line{
    border-bottom: calcRem(3px) solid $blue;
    margin-bottom: calcRem($fontSizeXLg);
  }

  &__total-container{
    display: grid;
    margin-top: calcRem(10px);
    flex-direction: row;
  }

  &__total-box{
    background-color: $lightBlue;
    padding:calcRem(10px);
    font-weight: bold;
    font-size: $fontSizeMd;
    border-radius: calcRem(8px);
    justify-self: flex-end;
  }

  &__validation-error{
    color: red;
  }

}


.striped-table th, .striped-table td {
  padding: calcRem(8px);
  white-space: nowrap;
}


.striped-table tr:nth-child(odd) {
  background-color: $white;
}

.striped-table th {
  padding-top: calcRem(12px);
  padding-bottom: calcRem(12px);
  text-align: left;
  background-color: $offwhite;
  font-size: $fontSizeMd;
  color: $black;
  white-space: nowrap;
}
.fixed-column {
  position: sticky;
  left: 0;
  @include media-breakpoint-down(md) {
    padding-right: calcRem(70px) !important;
  }
}

.fixed-column-title {
  position: sticky;
  left: 0;
  z-index: 2;
  @include media-breakpoint-down(md) {
    padding-right: calcRem(70px) !important;
  }
}
tbody tr td:first-child{
  position: sticky;
  left: 0;
  background-color: $white;
  z-index: 2;
}
thead tr th:first-child{
  position: sticky;
  left: 0;
  background-color: $white;
  z-index: 2;
}

.striped-table > tbody > tr:nth-of-type(even) > * {
  background-color: $offwhite !important;
}

.react-datepicker-popper {
  z-index: 3 !important;
}



