@import "../../styles/functions";
@import "../../styles/variables";
@import "~bootstrap/scss/bootstrap";

.auction-entry{

  &__box{
    font-size: calcRem($fontSizeSm);
    background-color: $white;
    border-radius: calcRem(6px);
    padding: calcRem(5px) calcRem(20px) calcRem(20px) calcRem(20px);
    margin-bottom: calcRem(20px);
  }
  &__title{
    font-size: $fontSizeLg;
    font-weight: bold;
    @include media-breakpoint-down(md) {
      font-size: calcRem(15px);
    }
    &-description{
      font-size: $fontSizeMd;
      font-weight: normal;
    }
  }
  &__minimum-bid{
    font-size: $fontSizeMd;
    color:$grey;
  }
  &__current-bid{
    background-color: $blue;
    color: $white;
    padding:calcRem(10px);
    border-radius: calcRem(6px);
    box-shadow: 0 calcRem(5px) calcRem(2px) #00000029;
    &--grey{
      background-color: $grey !important;
      width: 100%;
      margin-bottom: calcRem(10px);
    }
  }
  &__label--bold{
    font-weight: bold;
  }
  &__message{
    font-size: calcRem($fontSizeSm);
    background-color: $white;
    padding: calcRem(6px) calcRem(6px) calcRem(6px) calcRem(6px);
    &-success{
      background-color: $lightGreen;
      border: 1px solid #14C730;
      border-radius: calcRem(6px);
    }
    &-broadcast{
      background-color: $lightRed;
      border: 1px solid #DE6D2F;
      border-radius: calcRem(6px);
    }
    &-error{
      background-color: $lightRed;
      border: 1px solid #DE6D2F;
      border-radius: calcRem(6px);
    }
  }
  &__time{
    display:flex;
    position: relative;
    padding: calcRem(6px) calcRem(9px) calcRem(6px) calcRem(9px);
    left: calcRem(20px);
    background-color: $black;
    color:$white;
    border-radius: calcRem(6px);
    border: calcRem(1px) transparent;
    box-shadow: 0 calcRem(5px) calcRem(2px) #00000029;
  }
  &__time__text{
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    font-size: calcRem(30px);
    @include media-breakpoint-down(md) {
      font-size: calcRem(20px);
    }
    @include media-breakpoint-down(sm) {
      font-size: calcRem(15px);
    }
  }
}
.form-control {
  width: 100%;
  border: thin solid $grey;
  border-radius: calcRem(100px) !important;
  font-size: calcRem($fontSizeMd);
}
.auction-entry__messages .auction-entry__message:not(:first-of-type) {
  filter: grayscale(100%) opacity(75%);
}
