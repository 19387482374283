@import "../../styles/functions";
@import "../../styles/variables";
@import "../../../../node_modules/bootstrap/scss/bootstrap";

$padding-text : calcRem(6px) calcRem(9px) calcRem(6px) calcRem(9px);
$padding-mobile-text:calcRem(5px) calcRem(8px) calcRem(5px) calcRem(8px);
@mixin status {
  display: flex;
  min-width: calcRem(45px);
  max-width: calcRem(200px);
  padding: $padding-text;
  background-color: $white;
  border-top-left-radius: calcRem(8px);
  border-bottom-left-radius: calcRem(8px);
  border: calcRem(1px) transparent;
  position: absolute;
  top: calcRem(20px);
  right: 0;
  align-items: center;
  text-align: left;
  color: $black;
  box-shadow: 0 calcRem(5px) calcRem(2px) #00000029;
  font-size: $fontSizeSm;
}
.card{
  width:100%;
  min-height: calcRem(337px);
  border-radius: calcRem(6px);
  border: calcRem(1px) transparent;
  font-size: calcRem($fontSizeSm);
  background-color: $white;
  &-reduced {
    min-height: calcRem(150px);
    border-top: calcRem(1px) solid $grey-border;
    border-left: calcRem(1px) solid $grey-border;
    border-right: calcRem(1px) solid $grey-border;
    border-radius: calcRem(6px);
    font-size: calcRem($fontSizeSm);
    cursor: pointer;

    &__button {
      display: flex;
      justify-content: flex-end;
      margin-top: calcRem(10px);
      margin-right: calcRem(10px);
    }

    &__details {
      padding: 0 calcRem(20px) 0 calcRem(20px);
    }
  }

  &__img{
    width: 100%;
    height: calcRem(221px);
    object-fit: cover;
    object-position: center;
    border-top-left-radius: calcRem(6px);
    border-top-right-radius: calcRem(6px);
    display: block;
    position: relative;
  }
  &__img__container {
    position: relative;
  }

  &__select{
    position: absolute;
    top: calcRem(-12px);
    right: calcRem(-8px);
  }

  &__edit__container{
    display: flex;
    gap: calcRem(10px);
    position: absolute;
    top: calcRem(20px);
    left: calcRem(20px);
  }

  &__edit{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $blue;
    width: calcRem(35px);
    height: calcRem(35px);
    border-radius: calcRem(8px);
    opacity: 0;
    transition: opacity 0.3s ease;
    &__img{
      width: calcRem(25px);
      height: calcRem(25px);
    }
  }

  &__edit:hover  {
    cursor: pointer;
  }

  &__share-alt{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $blue;
    width: calcRem(35px);
    height: calcRem(35px);
    border-radius: calcRem(8px);
    opacity: 0;
    transition: opacity 0.3s ease;
    &__img{
      width: calcRem(25px);
      height: calcRem(25px);
    }
  }


  &__share{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $blue;
    width: calcRem(35px);
    height: calcRem(35px);
    border-radius: calcRem(8px);
    opacity: 0;
    transition: opacity 0.3s ease;
    &__img{
      width: calcRem(25px);
      height: calcRem(25px);
    }
  }

  &__share:hover  {
    cursor: pointer;
  }

  &__share-alt:hover  {
    cursor: pointer;
  }

  &__status {
    @include status;
    &-public {
      background-color: white; // Vibrant Light Blue
    }

    &-private {
      background-color: #ABB2B9; // Muted Grey
    }

    &-in-review {
      background-color: #5DADE2; // Sunny Yellow
    }

    &-reported {
      background-color: #E74C3C; // Bright Red
    }

    &-sold {
      background-color: #2ECC71; // Bold Green
    }

    &-reserved {
      background-color: #F39C12; // Rich Orange
    }

    &-expired {
      background-color: #D3A7D1; // Cool Dark Grey
    }

    &-reused {
      background-color: #D29E7F; // Aqua Teal
    }

    &-wanted {
      background-color: #719a68; // pale green ish.
    }

    &-awaiting-information {
      background-color: #FFD700; // Golden Yellow
    }
  }

  &__status-auction {
    @include status;
    &-public {
      background-color: white; // Vibrant Light Blue
    }
    &-private {
      background-color: #ABB2B9; // Muted Grey
    }
    top: calcRem(60px);
  }
  &__status-hover:hover  {
    cursor: pointer;
  }
  &__status-auction-hover:hover  {
    cursor: pointer;
  }
  &__secondary-logo{
    position: absolute;
    bottom: calcRem(20px);
    right: calcRem(35px);

    &__img{
      background-color: $white;
      height: calcRem(69px);
      width: calcRem(69px);
      object-fit: contain;
      object-position: center;
      border-radius: 50%;
      box-shadow: 0 calcRem(9px) calcRem(8px) #00000029;
    }
  }
  &__highest-bid{
    position: absolute;
    bottom: calcRem(20px);
    right:0;
    background-color: $black;
    color:$white;
    border-bottom-left-radius: calcRem(6px);
    border-top-left-radius: calcRem(6px);
    border: calcRem(1px) transparent;
    padding:$padding-text;
    box-shadow: 0 calcRem(5px) calcRem(2px) #00000029;
    &--green{
      background-color: #2ECC71;
    }
    &--red{
      background-color: #E74C3C;
    }

  }

  &__link{
    display:block;
    padding: $padding-text;
    max-width: calcRem(250px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    background-color: $white;
    border-radius: calcRem(6px);
    border: calcRem(1px) transparent;
    cursor: pointer;
    position: absolute;
    top: calcRem(20px);
    left: calcRem(20px);
    color: $blue;
    opacity: 0;
  }

  &__button{
    position: absolute;
    bottom: calcRem(20px);
    left: calcRem(20px);
    opacity: 0;
    transition: opacity 0.3s ease;
    .e-button{
      margin: 0;
    }
  }

  .card:hover &__button {
    opacity: 1;
  }
  .card:hover &__edit{
    opacity: 1;
  }
  .card:hover &__share{
    opacity: 1;
  }

  .card:hover &__share-alt{
    opacity: 1;
  }

  .card:hover &__link{
    opacity: 1;
  }

  &__details{
    display:flex;
    flex-grow: 1;
    min-height:calcRem(116px);
    flex-direction:row;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding:calcRem(20px);
    border-bottom: calcRem(1px) solid $grey-border;
    border-bottom-right-radius: calcRem(6px);
    border-bottom-left-radius: calcRem(6px);
    &--basic{
      flex-direction: column;
    }
    &__see__more{
      font-weight: bold;
      color: $grey;
    }
  }
  &__left{
    display:flex;
    flex-direction:column;
    flex-wrap: nowrap;
    width: 50%;
    &--basic{
      width:100%;
    }
  }
  &__right{
    display:flex;
    justify-content: flex-end;
    text-align: end;
    width: 50%;
    &--basic{
      width:100%;
    }
  }
  &__title{
    display: block;
    font-size: calcRem($fontSizeMd);
    color: $black;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &__match{
    color: $grey;
    text-align: right;
   }
  &__description{
    display: block;
    max-height:calcRem(40px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: $black;

    &__expanded{
      height: auto;
      white-space: initial;
      overflow: visible ;
      text-overflow: ellipsis;
      transition: max-height 0.25s ease-out;
      color: $black;
    }
  }
  &__time{
    display:flex;
    position: relative;
    padding: $padding-text;
    left: calcRem(20px);
    background-color: $black;
    color:$white;
    border-radius: calcRem(6px);
    border: calcRem(1px) transparent;
    box-shadow: 0 calcRem(5px) calcRem(2px) #00000029;
    &--hidden{
      visibility: hidden;
    }
  }
  &__time__text{
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
  }

  &__children{
    padding: calcRem(12px) calcRem(20px) calcRem(20px) calcRem(20px);
    border-bottom: calcRem(1px) solid $grey-border;
    border-top: calcRem(1px) solid $grey-border;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: calcRem(6px);
    border-bottom-right-radius: calcRem(6px);
    margin-bottom: calcRem(20px);

    &.selected-children{
      border-bottom: 1px solid $blue;
    }
  }
}

.selected{
  border-top: 1px $blue solid !important;
  border-left: 1px $blue solid;
  border-right: 1px $blue solid;
}

@media (max-width: 1199px) {
  .card{
    min-height: calcRem(248px);
    font-size: calcRem($fontSizeMobileSm);
    &__img{
      height: calcRem(186px);
    }
    &__button{
      opacity: 1;
    }
    &__edit{
      opacity: 1;
    }
    &__share{
      opacity: 1;
    }
    &__share-alt{
      opacity: 1;
    }
    &__title{
      font-size: calcRem($fontSizeMobileMd);
    }
    &__details{
      min-height:calcRem(116px);
    }

    &__status{
      padding: $padding-mobile-text;
      font-size: $fontSizeMobileSm;
    }
    &__secondary-logo{
      &__img {
        width: calcRem(31px);
        height: calcRem(31px);
      }
    }
    &__link{
      max-width: calcRem(100px);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding: $padding-mobile-text;
      opacity: 1;
    }
    &__highest-bid{
      padding: $padding-mobile-text;
    }
    &__time{
      padding: $padding-mobile-text;
    }
  }
}

.hover-button-down {
  top: calcRem(60px);
}
